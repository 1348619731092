export var SearchList = [
  { labe: '地址名称', code: 'addressName', type: 'input' },
  { labe: '地质属性', code: 'addressProperty', type: 'input' }
]
export var SearchData = {
  addressName: '',
  addressProperty: ''
}
export var tableField = [
  { label: '序号', type: 'function', width: '50px', handle: (index, data) => { return index + 1 } },
  { label: '地址名称', code: 'addressName', type: 'input', width: '' },
  {
    label: '地址属性',
    code: 'addressProperty',
    type: 'function',
    width: '',
    handle: (index, data) => {
      return data.addressProperty === '1' ? '返修地址' : data.addressProperty === '2' ? '仓库地址' : '其他地址'
    }
  },
  { label: '地址', type: 'function', width: '', handle: (index, data) => { return data.province + data.city + data.area + data.detailedAddress } },
  { label: '库位', code: 'minBatch', type: 'input', width: '' },
  { label: '联系人', code: 'contactPerson', type: 'input', width: '' },
  { label: '联系方式', code: 'contactWay', type: 'input', width: '' },
  {
    label: '操作',
    code: 'act',
    width: '',
    type: 'action',
    actionData: [{
      actionLabel: '编辑',
      id: '10'
    }, {
      actionLabel: '删除',
      id: '11'
    }]
  }
]
